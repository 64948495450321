/*
	Theme Name: Website WiWi
	Theme URI:
	Author URI: wiwi
	Version: 9.9.9
	License: GNU General Public License, version 3 (GPLv3)
	License URI: license.txt
*/

$green: #7bcc2e;
$blue: #15c1f3;

@mixin v-center($pos:relative) {
  position: $pos;
  top: 50%;
  transform: translateY(-50%);
}

@mixin h-center($pos:relative) {
  position: $pos;
  left: 50%;
  transform: translateX(-50%);
}

@mixin overlay() {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin outline($color:red) {
  outline: 1px solid $color;
}

@mixin transit($what: all, $when: 0.25s, $how: ease) {
  transition: $what $when $how;
}

@for $i from 1 through 20 {
  .#{mb}#{$i*5} {
    margin-bottom: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{mt}#{$i*5} {
    margin-top: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{pb}#{$i*5} {
    padding-bottom: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{pt}#{$i*5} {
    padding-top: #{$i*5}px;
  }
}

@for $i from 1 through 10 {
  .#{z}#{$i} {
    z-index: #{$i};
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($green, 0.6);
  }
  20% {
      box-shadow: 0 0 0 20px rgba($green, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba($green, 0);
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0 0 rgba(#fff, 0.6);
  }
  20% {
      box-shadow: 0 0 0 20px rgba(#fff, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes rotateMe {
	0% {
	opacity:0.2;}
	100% {
	opacity:1;} 
}