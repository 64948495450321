/*<style>*/

/*
	Theme Name: Website WiWi
	Theme URI:
	Author URI: wiwi
	Version: 9.9.9
	License: GNU General Public License, version 3 (GPLv3)
	License URI: license.txt
*/

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb65 {
  margin-bottom: 65px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb75 {
  margin-bottom: 75px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb85 {
  margin-bottom: 85px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb95 {
  margin-bottom: 95px;
}

.mb100 {
  margin-bottom: 100px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt55 {
  margin-top: 55px;
}

.mt60 {
  margin-top: 60px;
}

.mt65 {
  margin-top: 65px;
}

.mt70 {
  margin-top: 70px;
}

.mt75 {
  margin-top: 75px;
}

.mt80 {
  margin-top: 80px;
}

.mt85 {
  margin-top: 85px;
}

.mt90 {
  margin-top: 90px;
}

.mt95 {
  margin-top: 95px;
}

.mt100 {
  margin-top: 100px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb55 {
  padding-bottom: 55px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb65 {
  padding-bottom: 65px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb75 {
  padding-bottom: 75px;
}

.pb80 {
  padding-bottom: 80px;
}

.pb85 {
  padding-bottom: 85px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb95 {
  padding-bottom: 95px;
}

.pb100 {
  padding-bottom: 100px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt55 {
  padding-top: 55px;
}

.pt60 {
  padding-top: 60px;
}

.pt65 {
  padding-top: 65px;
}

.pt70 {
  padding-top: 70px;
}

.pt75 {
  padding-top: 75px;
}

.pt80 {
  padding-top: 80px;
}

.pt85 {
  padding-top: 85px;
}

.pt90 {
  padding-top: 90px;
}

.pt95 {
  padding-top: 95px;
}

.pt100 {
  padding-top: 100px;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z5 {
  z-index: 5;
}

.z6 {
  z-index: 6;
}

.z7 {
  z-index: 7;
}

.z8 {
  z-index: 8;
}

.z9 {
  z-index: 9;
}

.z10 {
  z-index: 10;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(123, 204, 46, 0.6);
  }

  20% {
    box-shadow: 0 0 0 20px rgba(123, 204, 46, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(123, 204, 46, 0);
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }

  20% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes rotateMe {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

/*@font-face {
font-family: 'Open Sans';
src: url(fonts/OpenSans-Regular.ttf);
font-weight:400;
}

@font-face {
font-family: 'Open Sans';
src: url(fonts/OpenSans-Bold.ttf);
font-weight:700;
}*/

html,
body {
  overflow-x: hidden;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #fff;
  font-size: 18px;
  color: #000;
  min-width: 320px;
  padding-top: 160px;
}

img {
  max-width: 100%;
  height: auto;
}

body.customize-support .navbar-fixed-top {
  top: 32px;
}

a {
  transition: color .1s ease-in, background .1s ease-in;
}

a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}

/*button,img,span,em,hr,h3,p,div,strong{transition:all .25s ease-in}*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.oberMenu .navbar {
  transition: all .3s;
  margin-top: 40px;
}

.navbar-nav.mynavbartop {
  margin-left: auto;
}

.oberMenu .navbar-nav > li > a:hover,
.oberMenu .navbar-nav > li > a:focus,
.oberMenu .navbar-nav > li > a:active {
  background: 0;
}

.oberMenu .navbar-nav > li > a {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  transition: all .3s;
  padding: 4px 0;
}

.oberMenu .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.oberMenu .navbar-nav > li {
  padding: 0 25px;
  /*background:blue;*/
  position: relative;
}

.oberMenu .navbar-nav > li:last-child {
  padding-right: 0;
}

.oberMenu .navbar-nav > li:first-child {
  padding-left: 0;
}

.nav-link.dropdown-toggle {
  background: black;
  color: #fff !important;
  font-weight: 700 !important;
  padding-left: 18px !important;
  padding-right: 38px !important;
  position: relative;
}

.nav-link.dropdown-toggle:after {
  display: none;
}

.nav-link.dropdown-toggle:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 6px;
  background: url(images/trianglelangs.svg) no-repeat;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
}

.nav-link.dropdown-toggle:hover,
.nav-link.dropdown-toggle:focus {
  background: #000 !important;
}

.nav-link.dropdown-toggle[aria-expanded="false"] {
  border-radius: 20px;
}

.nav-link.dropdown-toggle[aria-expanded="true"] {
  border-radius: 20px 20px 0 0;
}

.dropdown-menu {
  border-radius: 20px 0 20px 20px;
  background: #000;
  left: auto;
  right: 0;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  min-width: 1px;
  padding: 10px 0;
}

.dropdown-menu a {
  font-weight: 700;
  padding: 0 20px;
  color: #fff;
}

.dropdown-menu a:hover,
.dropdown-menu a:focus {
  color: #7bcc2e;
  background: transparent;
}

.sub-menu.dropdown-menu a span {
  font-weight: 400;
}

.navbar-default .navbar-nav > li > a:focus {
  color: #000000;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  background-color: transparent;
}

.oberMenu .navbar-nav > li.current-menu-item > a {
  color: #7bcc2e !important;
  font-weight: 700;
}

.oberMenu .navbar-nav > li > a:focus {
  color: #000000;
}

.oberMenu .navbar-nav > li > a:hover {
  color: #7bcc2e !important;
}

.shrink.oberMenu .navbar,
.shrink.oberMenu .navbar.innerNavbar {
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

/**/

/**/

img.alignright {
  float: right;
  margin: 0 0 2em 2em;
}

img.alignleft {
  float: left;
  margin: 0 2em 2em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a.fill_div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.relative {
  position: relative;
}

footer {
  margin-top: 150px;
  padding-bottom: 70px;
}

footer.kont {
  margin-top: 100px;
}

/**/

.navigation {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: 700;
  color: #000;
}

.page-numbers {
  color: #717274;
  padding: 0 10px;
}

.page-numbers:hover,
.page-numbers.current {
  color: #000;
}

/**/

.singleH1 {
  color: #cd373d;
  font-weight: 900;
}

/**/

.formNestedRow {
  color: #7bcc2e;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
}

.formNestedRow p {
  margin-bottom: 0;
}

.formNestedRow.myfoot ::-webkit-input-placeholder {
  opacity: 0.7;
  color: #7bcc2e;
}

.formNestedRow.myfoot ::-moz-placeholder {
  opacity: 0.7;
  color: #7bcc2e;
}

.formNestedRow.myfoot :-ms-input-placeholder {
  opacity: 0.7;
  color: #7bcc2e;
}

.formNestedRow.myfoot :-moz-placeholder {
  opacity: 0.7;
  color: #7bcc2e;
}

.formNestedRow.rowKontaktForm ::-webkit-input-placeholder {
  opacity: 1;
  color: #fff;
}

.formNestedRow.rowKontaktForm ::-moz-placeholder {
  opacity: 1;
  color: #fff;
}

.formNestedRow.rowKontaktForm :-ms-input-placeholder {
  opacity: 1;
  color: #fff;
}

.formNestedRow.rowKontaktForm :-moz-placeholder {
  opacity: 1;
  color: #fff;
}

.rowKontaktForm .wpcf7-form {
  display: flex;
  flex-wrap: wrap;
}

.formNestedRow input:hover,
.formNestedRow textarea:hover,
.formNestedRow input:focus,
.formNestedRow textarea:focus {
  background-color: rgba(247, 255, 239, 0.33);
}

.formNestedRow input,
.formNestedRow textarea {
  border-radius: 8px;
  border: 1px solid rgba(123, 204, 46, 0.33);
  background: #fff;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 300;
}

.formNestedRow.rowKontaktForm input,
.formNestedRow.rowKontaktForm textarea {
  border-radius: 0;
  border: 0;
  border-bottom: 3px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formNestedRow textarea {
  resize: vertical;
  height: 90px;
  min-height: 90px;
  max-height: 150px;
  margin-bottom: 20px;
}

.formNestedRow.rowKontaktForm textarea {
  height: 130px;
}

.rowKontaktForm div.wpcf7-validation-errors,
.rowKontaktForm div.wpcf7-acceptance-missing {
  color: #fff;
  width: 100%;
}

.formNestedRow input.wpcf7-submit {
  background: #7bcc2e;
  padding: 10px 30px;
  display: block;
  float: right;
  width: auto;
  cursor: pointer;
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 21px;
}

.rowKontaktForm.formNestedRow input.wpcf7-submit {
  float: none;
  border: 0;
  margin: 15px auto 0 auto;
  background: #fff url(images/trianglerightblue.svg) no-repeat;
  background-position: right 25px center;
  padding: 14px 60px 14px 40px;
  border-radius: 34px;
  color: #15c1f3;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  color: #15c1f3;
}

.rowKontaktForm.formNestedRow input.wpcf7-submit:before {
  content: '';
  position: absolute;
  width: calc(100% + 28px);
  height: calc(100% + 28px);
  left: -14px;
  top: -14px;
  border-radius: 39px;
  border: 0;
  background: rgba(255, 255, 255, 0.2);
}

.rowKontaktForm.formNestedRow input.wpcf7-submit:hover {
  animation: pulseWhite 4s;
}

.formNestedRow input.wpcf7-submit:hover {
  animation: pulse 4s;
}

/**/

.navbar-toggler {
  cursor: pointer;
  position: relative;
  width: 2.7rem;
  height: 2.5rem;
  padding: .5rem .75rem;
  font-size: 1.25rem;
  line-height: 1rem;
  border-radius: 0;
  background: #15c1f3;
}

.navbar-toggler span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #fff;
  border-radius: 3px;
  opacity: 1;
  left: 25%;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.navbar-toggler span:nth-child(1) {
  top: 30.5%;
}

.navbar-toggler span:nth-child(2),
.navbar-toggler span:nth-child(3) {
  top: 49%;
}

.navbar-toggler span:nth-child(4) {
  top: 66.5%;
}

.navbar-toggler.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navbar-toggler.open span:nth-child(2) {
  transform: rotate(45deg);
}

.navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.navbar-toggler.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/**/

ul.slick-dots {
  position: absolute;
  /*transform:translateY(calc(-50% - 140px));*/
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: center;
  padding: 0;
}

ul.slick-dots li {
  display: inline;
  padding: 0 20px;
}

ul.slick-dots li button {
  background: url(images/bullet_brown.png) no-repeat center;
  border: 0;
  border-radius: 50%;
  font-size: 0px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  outline: none;
}

ul.slick-dots li.slick-active button {
  background-image: url(images/bullet_green.png);
  height: 15px;
  width: 15px;
}

.wrapSliderHome {
  position: relative;
}

.wrapSliderHome .prevSlid,
.wrapSliderHome .nextSlid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
}

.wrapSliderHome .prevSlid {
  left: 50px;
}

.wrapSliderHome .nextSlid {
  right: 50px;
}

.wrapSliderHome {
  position: relative;
}

.wrapSliderHome ul.slick-dots {
  top: 140px;
}

/* ==== Slider Style === */

.Modern-Slider {
  position: relative;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text;
  /*imgfill*/
}

.Modern-Slider .img-fill {
  height: 640px;
  /*background-size:cover;*/
  /*background-attachment:fixed;*/
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.Modern-Slider .img-fill .triangleSlider {
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

.Modern-Slider .img-fill .triangleSlider img {
  width: 100%;
}

.Modern-Slider .img-fill .info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 100%;
  z-index: 3;
}

.Modern-Slider .img-fill h3 {
  color: #FFF;
  font-size: 130px;
  font-weight: 700;
  letter-spacing: 20px;
  visibility: hidden;
  text-align: center;
  line-height: 1;
}

.Modern-Slider .img-fill p {
  max-width: 650px;
  font-size: 51px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 10px;
  margin: 0 auto;
  color: #FFF;
  visibility: hidden;
  text-align: center;
}

.Modern-Slider .slick-active h3 {
  animation: fadeInDown 1s both 0.5s;
  visibility: visible;
}

.Modern-Slider .slick-active p {
  animation: fadeInUp 1s both 1s;
  visibility: visible;
}

/* ==== Slick Slider Css Ruls === */

.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/**/

/*slick several like logas*/

.subwrapClientImg {
  padding-right: 25px;
  padding-left: 25px;
  text-align: center;
}

.subwrapClientImg .nazwakomp {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
}

.subwrapClientImg img {
  display: inline;
  height: 371px;
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
}

/**/

.lefttopbluehome {
  position: absolute;
  z-index: -1;
  width: 1600px;
  height: auto;
  left: -600px;
  top: -800px;
}

.abselementkorzysci {
  position: absolute;
  z-index: -1;
  width: 1400px;
  height: auto;
  left: -100px;
  top: 174px;
}

.elementhomeright {
  position: absolute;
  z-index: -1;
  width: 1000px;
  height: auto;
  right: -500px;
  top: 220px;
}

.footerelement {
  position: absolute;
  z-index: -1;
  width: 1400px;
  height: auto;
  left: 70px;
  bottom: 70px;
}

.footerelement.kont {
  left: 300px;
  bottom: 0;
  width: 1000px;
}

.elementonas {
  position: absolute;
  z-index: -1;
  width: 1700px;
  height: auto;
  right: -400px;
  top: -750px;
}

.hasloSlid1 {
  color: #7bcc2e;
  font-size: 73px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
}

.hasloSlid2 {
  font-size: 59px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 30px;
}

.opisSlid {
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  margin-bottom: 30px;
}

.btnGreen {
  background: #7bcc2e url(images/trianglebtn.svg) no-repeat;
  background-position: right 25px center;
  display: inline-block;
  padding: 14px 60px 14px 40px;
  border-radius: 34px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  position: relative;
}

.btnGreen:before {
  content: '';
  position: absolute;
  width: calc(100% + 28px);
  height: calc(100% + 28px);
  left: -14px;
  top: -14px;
  border-radius: 39px;
  border: 1px solid rgba(123, 204, 46, 0.22);
}

.btnGreen:hover {
  color: #fff;
  animation: pulse 4s;
}

.btnGreen.btnWhite {
  color: #15c1f3;
  background-color: #fff;
  background-image: url(images/trianglerightblue.svg);
}

.btnGreen.btnWhite:before {
  border: 0;
  background: rgba(255, 255, 255, 0.2);
}

.btnGreen.btnWhite:hover {
  animation: pulseWhite 4s;
}

.btnGreen.btnSimpleGreen {
  background-image: none;
  padding: 10px 30px;
}

.wrapBtnKosztyHome {
  position: relative;
  top: 33px;
}

.myskroll {
  position: absolute;
  right: 15px;
  bottom: 0;
  color: #83b7eb;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
}

.pl14 {
  padding-left: 14px;
}

.contSlid {
  margin-top: 60px;
  margin-bottom: 240px;
}

.opis {
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
}

.opis h1,
.opis h2,
.opis h3,
.opis h4,
.opis h5,
.opis h6 {
  font-size: 48px;
  font-weight: 700;
  line-height: 86.2px;
}

.opis.opisReduceH h1,
.opis.opisReduceH h2,
.opis.opisReduceH h3,
.opis.opisReduceH h4,
.opis.opisReduceH h5,
.opis.opisReduceH h6 {
  font-size: 24px;
  font-weight: 700;
  line-height: 67px;
}

.opis.opisSpecjalnosci p:last-child {
  margin-bottom: 0;
}

.opis.opisSpecjalnosci h1,
.opis.opisSpecjalnosci h2,
.opis.opisSpecjalnosci h3,
.opis.opisSpecjalnosci h4,
.opis.opisSpecjalnosci h5,
.opis.opisSpecjalnosci h6 {
  font-size: 36px;
  font-weight: 700;
  line-height: 51px;
  margin-bottom: 20px;
}

.opis.opisSpecjalnosci.lh17 h1,
.opis.opisSpecjalnosci h2,
.opis.opisSpecjalnosci h3,
.opis.opisSpecjalnosci h4,
.opis.opisSpecjalnosci h5,
.opis.opisSpecjalnosci h6 {
  line-height: 1.5;
}

.opis.opisKariera {
  padding-top: 40px;
}

.opis.opisKariera table {
  margin-bottom: 30px;
}

.opis.opisKariera td {
  padding-right: 100px;
}

.colImgCechy .sub {
  /*border:1px solid red;*/
  position: relative;
  padding-bottom: 68px;
  height: 100%;
  /*&:before {content:'';position:absolute;left: 50%;bottom: 0;width: 2px;height: 100%;background:#e5e5e5;
			z-index:-1;display: none;
		}*/
}

.colImgCechy .sub .subbefore {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 2px;
  height: 100%;
  background: #e5e5e5;
  z-index: -1;
}

.colImgCechy .subsub {
  background: #fff;
}

.colOpisOrderLast {
  order: 2;
}

.kosztyikonka {
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 32px;
  z-index: 1;
}

.colKoszty .sub {
  margin: 0 90px;
  background: url(images/tlo.jpg) no-repeat;
  background-attachment: fixed;
  border-radius: 270px;
  box-shadow: 0px 10px 16px 0px rgba(20, 130, 219, 0.41);
  padding: 140px 160px 0 160px;
  color: #fff;
  text-align: justify;
  text-align-last: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
}

.colKoszty .sub h1,
.colKoszty .sub h2,
.colKoszty .sub h3,
.colKoszty .sub h4,
.colKoszty .sub h5,
.colKoszty .sub h6 {
  font-size: 48px;
  font-weight: 700;
  line-height: 86.2px;
  margin-bottom: 10px;
}

.colKoszty .subOpis {
  padding-bottom: 30px;
}

.contKoszty {
  margin-bottom: 190px;
}

.rowKosztyIcon {
  margin-bottom: -130px;
}

.opisKorzysci {
  margin-bottom: 50px;
}

.opisKorzysci p {
  width: 580px;
  margin: 0 auto;
  max-width: 100%;
}

.row22 {
  margin-left: -22px;
  margin-right: -22px;
}

.col22 {
  padding-left: 22px;
  padding-right: 22px;
}

.row26 {
  margin-left: -26px;
  margin-right: -26px;
}

.col26 {
  padding-left: 26px;
  padding-right: 26px;
}

.colKorzysci {
  margin-bottom: 44px;
}

.colKorzysci .sub {
  box-shadow: 0 18px 40px rgba(99, 185, 249, 0.36);
  border-radius: 15px;
  padding: 35px 15px 15px 15px;
  height: 100%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  background: #fff;
}

.colKorzysci .korzysciIcon {
  margin-bottom: 30px;
}

.korzysciSection {
  margin-bottom: 140px;
}

.contKompetencja {
  margin-bottom: 140px;
}

.contKompetencja .sub {
  margin: 0 50px;
  padding: 0 30px;
}

.contKompetencja .sub .galeriaSlick .prevSlid,
.contKompetencja .sub .galeriaSlick .nextSlid {
  border-radius: 60px;
  overflow: hidden;
  position: absolute;
  transform: translateY(-50%);
  top: calc(50% - 21px);
  z-index: 5;
  cursor: pointer;
}

.contKompetencja .sub .galeriaSlick .prevSlid:hover,
.contKompetencja .sub .galeriaSlick .nextSlid:hover {
  animation: pulse 4s;
}

.contKompetencja .sub .galeriaSlick .prevSlid {
  left: -70px;
}

.contKompetencja .sub .galeriaSlick .nextSlid {
  right: -70px;
}

.contPracaHome {
  margin-bottom: 100px;
  background: url(images/praca.png) no-repeat center;
  padding-top: 130px;
  padding-bottom: 130px;
}

.contPracaHome .opisPracaHome {
  color: #fff;
}

.opisPracaHome .sub {
  padding: 0 40px;
}

.linkiFooter a {
  margin: 0 10px;
}

.opisF {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.opisF p {
  margin-bottom: 0;
}

.colDaneFooter .sub {
  box-shadow: 0 18px 40px rgba(99, 185, 249, 0.36);
  border-radius: 15px;
  padding: 50px 40px 20px 40px;
  background: #fff;
  height: 100%;
}

.tableDaneF {
  font-family: Poppins;
  font-size: 23px;
  font-weight: 400;
}

.tableDaneF a {
  color: #000;
}

.tableDaneF a:hover {
  color: #7bcc2e;
}

.tableDaneF td {
  vertical-align: top;
  padding-bottom: 20px;
}

.tableDaneF .myicon {
  padding-right: 10px;
}

.tableDaneF p {
  margin-bottom: 0;
}

.daneTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}

.daneTitle.green {
  color: #7bcc2e;
  margin-bottom: 20px;
}

.hasloGreen {
  color: #7bcc2e;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 5.61px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.opisHead {
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
}

.opisHead h1,
.opisHead h2,
.opisHead h3,
.opisHead h4,
.opisHead h5,
.opisHead h6 {
  color: #000000;
  font-size: 47px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
}

.head {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.contHeadOferta {
  margin-top: 100px;
  margin-bottom: 160px;
}

.contHeadOferta.contonas {
  margin-top: 70px;
}

.colObrazekInner .subForImg {
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.colObrazekInner .subForImg.kont {
  position: relative;
  left: 50px;
}

.colObrazekInner .subForImg:before {
  content: '';
  position: absolute;
  width: calc(100% + 70px);
  height: calc(100% + 70px);
  left: -35px;
  top: -35px;
  border-radius: 50%;
  border: 4px solid rgba(21, 193, 243, 0.1);
}

.colObrazekInner .subForImg:after {
  content: '';
  position: absolute;
  width: calc(100% + 140px);
  height: calc(100% + 140px);
  left: -70px;
  top: -70px;
  border-radius: 50%;
  border: 2px solid rgba(21, 193, 243, 0.12);
}

.colObrazekInner.onasObr .subForImg:before,
.colObrazekInner.onasObr .subForImg:after {
  border-color: #fff;
}

.contProcesOferta {
  margin-bottom: 130px;
}

.rowSpecjalnosciOferta {
  margin-bottom: 150px;
}

/**/

.wrapPytanie {
  background: #fff;
  margin-bottom: 30px;
}

.trescakk {
  cursor: pointer;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 86.2px;
  background: url(images/akordeonplus.svg) no-repeat right 25px center;
  padding: 0 40px;
  border: 3px solid #000000;
  border-radius: 49px;
  position: relative;
}

.trescakk p {
  margin-bottom: 0;
}

.trescakk:hover {
  background-color: #F4FBFF;
}

.trescakk:before {
  content: '';
  position: absolute;
  bottom: -3px;
  height: 3px;
  right: 25px;
  left: 25px;
  background: #15c1f3;
  display: none;
}

.trescakk[aria-expanded="true"] {
  background-image: url(images/akordeonminus.svg);
  border-radius: 49px 49px 0 0;
  border-color: #15c1f3;
  border-bottom: 0;
}

.trescakk[aria-expanded="true"]:before {
  display: block;
}

.contentCollapseAcc {
  border: 3px solid #15c1f3;
  border-top: 0;
  border-radius: 0 0 49px 49px;
  padding: 0 40px;
}

.karieraInt {
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
}

.karieraInt a {
  color: #7bcc2e;
}

.karieraInt a:hover {
  color: #000;
}

/**/

.hasloNaRynku {
  margin-top: 30px;
  color: #7bcc2e;
  border-left: 20px solid #7bcc2e;
  font-weight: 700;
  padding: 15px 0 15px 30px;
}

.hasloNaRynku .haslor1 {
  font-size: 149px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.hasloNaRynku .haslor2 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 700;
  line-height: 80px;
}

.opisCeleOnas {
  max-width: 100%;
  width: 840px;
  margin: 0 auto 90px auto;
}

.rowCeleOnas {
  margin-bottom: 130px;
}

.colObrCeleOnas img {
  border-radius: 180px;
}

.opisSpecjalnosci.ordFirst {
  order: -1;
}

.colGal {
  margin-bottom: 30px;
}

.colGal .sub:hover img {
  opacity: 0.5;
}

.colGal img {
  transition: all .3s;
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 9px;
}

.tos-caption {
  display: none;
}

.rowKontaktDane {
  margin-bottom: 100px;
}

.colDaneLeft {
  position: relative;
  z-index: 1;
}

.colDaneLeft.last {
  order: 2;
}

.colDaneLeft.last .sub {
  border-radius: 0 15px 15px 0;
}

.colDaneLeft .sub {
  box-shadow: 0 18px 40px rgba(99, 185, 249, 0.36);
  border-radius: 15px 0 0 15px;
  background-color: #ffffff;
  padding: 50px;
}

.colTitleKontakt {
  margin-bottom: 25px;
}

.titleKontakt {
  font-size: 45px;
  font-weight: 700;
  margin-right: 40px;
}

.daneFirma {
  font-family: Poppins;
  font-size: 23px;
  font-weight: 400;
  margin-bottom: 30px;
}

.colDaneRight.first .sub {
  border-radius: 15px 0 0 15px;
}

.colDaneRight .sub {
  height: 100%;
  border-radius: 0 15px 15px 0;
  overflow: hidden;
}

.acf-map {
  height: 100%;
  min-height: 250px;
}

.containerMaps {
  margin-top: -420px;
}

.contKontaktForm .sub {
  background: #28b3f2 url(images/formularzbg.png) no-repeat;
  background-position: -900px top;
  border-radius: 380px;
}

.contKontaktForm .sub .subSub {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

.titleKontaktForm {
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  margin: 0px 0 30px 0;
  padding-top: 40px;
}

.colCaptcha {
  text-align: center;
}

.colCaptcha .myrecaptcha {
  display: inline-block;
}

.wrapInlineBlSlid {
  position: relative;
}

.wrapInlineBlSlid .hatsSlid {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.wrapInlineBlSlid .hat1 {
  animation-delay: 1s;
}

.wrapInlineBlSlid .hat2 {
  animation-delay: 1s;
}

/*1800*/

/*1590*/

/*1200*/

/**/

/*1550*/

/*1590*/

/*1200*/

/*992*/

/*768*/

/*576*/

/*480*/

/*350*/

/*2000*/

/*</style>*/

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1590px) {
  .container.contWide {
    max-width: 1530px;
  }

  .container.contWideBut {
    max-width: 1530px;
  }

  .container.cont1550 {
    max-width: 1530px;
  }
}

@media (min-width: 1800px) {
  .container.contWide {
    max-width: 1740px;
  }

  .container.contWideBut {
    max-width: 1620px;
  }

  .container.cont1550 {
    max-width: 1550px;
  }
}

@media (min-width: 2000px) {
  .colKoszty .sub {
    background-size: cover;
  }
}

@media (max-width: 1799px) {
  .kosztyikonka {
    width: 180px;
  }

  .rowKosztyIcon {
    margin-bottom: -120px;
  }

  /**/

  .opisPracaHome .sub {
    padding: 0 0px;
  }

  .contPracaHome {
    background-size: contain;
  }
}

@media (max-width: 1589px) {
  .kosztyikonka {
    width: 160px;
    top: 22px;
  }

  .rowKosztyIcon {
    margin-bottom: -90px;
  }

  /**/

  body {
    padding-top: 120px;
    font-size: 16px;
  }

  .hasloSlid1 {
    font-size: 63px;
    margin-bottom: 15px;
  }

  .hasloSlid2 {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .kolkoSlidLap,
  .hatsSlid {
    width: 500px;
  }

  .kolkoInnerLap {
    width: 400px;
  }

  .contHeadOferta {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .contSlid {
    margin-top: 40px;
    margin-bottom: 120px;
  }

  .opisSlid,
  .opis,
  .colKoszty .sub,
  .opisHead {
    font-size: 16px;
    line-height: 30px;
  }

  .btnGreen {
    padding: 10px 60px 10px 40px;
    font-size: 18px;
  }

  .oberMenu .navbar-nav > li > a {
    font-size: 18px;
  }

  .oberMenu .navbar-nav > li {
    padding: 0 20px;
  }

  .opis h1,
  .opis h2,
  .opis h3,
  .opis h4,
  .opis h5,
  .opis h6,
  .opisHead h1,
  .opisHead h2,
  .opisHead h3,
  .opisHead h4,
  .opisHead h5,
  .opisHead h6 {
    font-size: 40px;
    line-height: 1.5;
  }

  .colKoszty .sub h1,
  .colKoszty .sub h2,
  .colKoszty .sub h3,
  .colKoszty .sub h4,
  .colKoszty .sub h5,
  .colKoszty .sub h6 {
    font-size: 40px;
    line-height: 1.5;
  }

  .dropdown-menu {
    font-size: 18px;
  }

  .colKoszty .sub {
    padding: 80px 60px 0 60px;
    border-radius: 170px;
    margin: 0;
  }

  .wrapBtnKosztyHome {
    top: 22px;
  }

  .contKoszty {
    margin-bottom: 150px;
  }

  .subwrapClientImg img {
    height: 270px;
  }

  .subwrapClientImg {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contKompetencja {
    margin-bottom: 60px;
  }

  .korzysciSection {
    margin-bottom: 100px;
  }

  .contPracaHome {
    margin-bottom: 40px;
  }

  .col26 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .row26 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .colDaneFooter .sub {
    padding: 30px 15px 15px 15px;
  }

  .tableDaneF {
    font-size: 20px;
  }

  .formNestedRow.myfoot textarea {
    height: 60px;
    min-height: 60px;
  }

  .formNestedRow.myfoot div.wpcf7 .ajax-loader {
    position: absolute;
  }

  .elementonas {
    top: -550px;
  }

  .hasloNaRynku .haslor1 {
    font-size: 130px;
  }

  .hasloNaRynku .haslor2 {
    font-size: 39px;
    line-height: 1.3;
  }

  .containerMaps {
    margin-top: -250px;
  }

  .contKontaktForm .sub {
    border-radius: 150px;
  }

  /**/

  .colObrazekInner .subForImg:before {
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    left: -15px;
    top: -15px;
  }

  .colObrazekInner .subForImg:after {
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    left: -30px;
    top: -30px;
  }

  /**/

  .subForImg.mt-4 {
    margin-top: 0 !important;
  }

  .trescakk {
    line-height: 2.5;
  }
}

@media (max-width: 1199.98px) {
  body {
    padding-top: 140px;
  }

  .oberMenu .navbar {
    margin-top: 20px;
  }

  .contHeader {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }

  .navbar-nav.mynavbartop {
    margin-left: 0;
  }

  .logo {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .mynavbarcollapse {
    justify-content: center;
  }

  .hasloSlid1 {
    font-size: 43px;
    margin-bottom: 10px;
  }

  .hasloSlid2 {
    font-size: 35px;
    margin-bottom: 5px;
  }

  .oberMenu .navbar-nav > li {
    padding: 0 15px;
  }

  .oberMenu .navbar-nav > li > a,
  .dropdown-menu {
    font-size: 17px;
  }

  .hasloSlid2 {
    font-size: 28px;
  }

  .opis h1,
  .opis h2,
  .opis h3,
  .opis h4,
  .opis h5,
  .opis h6,
  .opisHead h1,
  .opisHead h2,
  .opisHead h3,
  .opisHead h4,
  .opisHead h5,
  .opisHead h6 {
    font-size: 35px;
  }

  .colKoszty .sub h1,
  .colKoszty .sub h2,
  .colKoszty .sub h3,
  .colKoszty .sub h4,
  .colKoszty .sub h5,
  .colKoszty .sub h6 {
    font-size: 35px;
  }

  .opisSlid,
  .opis,
  .colKoszty .sub,
  .opisHead {
    font-size: 15px;
    line-height: 25px;
  }

  .kosztyikonka {
    width: 130px;
    top: 12px;
  }

  .colKorzysci .sub {
    font-size: 15px;
    line-height: 25px;
  }

  .rowKosztyIcon {
    margin-bottom: -65px;
  }

  .contPracaHome {
    background: #15c1f3;
    border-radius: 100px;
    padding: 50px 15px;
    text-align: center;
  }

  .cF1 {
    order: -2;
  }

  .cF4 {
    order: -1;
  }

  .cF1,
  .cF2,
  .cF3,
  .cF4 {
    margin-bottom: 30px;
  }

  .colObrazekInner .subForImg:before {
    width: calc(100% + 23px);
    height: calc(100% + 23px);
    left: -11.5px;
    top: -11.5px;
    border-width: 2px;
  }

  .colObrazekInner .subForImg:after {
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
  }

  .head {
    font-size: 40px;
  }

  .titleKontakt {
    font-size: 25px;
  }

  .tableDaneF,
  .daneFirma {
    font-size: 18px;
  }

  .titleKontaktForm {
    font-size: 38px;
  }
}

@media (max-width: 991.98px) {
  .opis h1,
  .opis h2,
  .opis h3,
  .opis h4,
  .opis h5,
  .opis h6,
  .opisHead h1,
  .opisHead h2,
  .opisHead h3,
  .opisHead h4,
  .opisHead h5,
  .opisHead h6 {
    font-size: 30px;
    line-height: 1.3;
  }

  .colKoszty .sub h1,
  .colKoszty .sub h2,
  .colKoszty .sub h3,
  .colKoszty .sub h4,
  .colKoszty .sub h5,
  .colKoszty .sub h6 {
    font-size: 30px;
    line-height: 1.3;
  }

  .colImgCechy .subbefore {
    width: 1px;
  }

  .rowKosztyIcon {
    margin-bottom: -45px;
  }

  .kosztyikonka {
    width: 86px;
    top: 12px;
  }

  .colKoszty .sub {
    padding-top: 60px;
  }

  .contHeadOferta {
    margin-bottom: 50px;
  }

  .hasloNaRynku .haslor1 {
    font-size: 100px;
  }

  .hasloNaRynku .haslor2 {
    font-size: 30px;
  }

  .containerMaps {
    margin-top: 0;
  }

  .colObrazekInner .subForImg.kont {
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .tos-pagination {
    display: none;
  }

  body {
    padding-top: 60px;
  }

  .navbar-expand-md > .container {
    flex-direction: row-reverse;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between !important;
  }

  .logo {
    width: auto;
    margin-bottom: 0;
  }

  .logo img {
    width: 190px;
  }

  .myskroll {
    display: none;
  }

  .oberMenu .navbar {
    margin-top: 0px;
  }

  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .oberMenu .navbar,
  .oberMenu .navbar.innerNavbar,
  .shrink.oberMenu .navbar,
  .shrink.oberMenu .navbar.innerNavbar {
    background: white !important;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }

  .kolkoSlidLap,
  .hatsSlid {
    margin: 0 auto 10px auto;
    padding: 0 20px;
  }

  .contSlid {
    margin-top: 10px;
  }

  .hasloSlid1,
  .hasloSlid2 {
    text-align: center;
  }

  .hasloSlid2 {
    margin-bottom: 15px;
  }

  .pl14 {
    padding-left: 0;
  }

  .lefttopbluehome {
    max-width: 1000px;
    width: 800px;
    height: auto;
    left: -400px;
    top: -350px;
  }

  .wrapBtnSlid {
    order: -1;
    margin: 10px 0 15px 0;
  }

  .btnGreen {
    background-position: right 14px center;
    background-size: 7px;
    padding: 7px 30px 7px 15px;
    border-radius: 34px;
    font-size: 17px;
  }

  .btnGreen:before {
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    left: -8px;
    top: -8px;
    border-radius: 39px;
    border: 1px solid rgba(123, 204, 46, 0.22);
  }

  .oberMenu .navbar-nav > li {
    padding: 0 0 0px 0;
  }

  .navbar-nav.mynavbartop {
    padding-top: 10px;
  }

  .oberMenu .dropdown {
    align-self: flex-start;
  }

  .dropdown-menu {
    border-radius: 0 0 20px 20px;
    padding-top: 0px !important;
  }

  .opisSlid {
    margin-bottom: 0;
    text-align: center;
  }

  .contSlid {
    margin-bottom: 20px;
  }

  .colImgCechy .subbefore {
    display: none;
  }

  .colImgCechy {
    order: 10;
  }

  .colOpisCechy {
    text-align: center !important;
  }

  .colImgCechy .sub {
    padding-bottom: 15px;
  }

  .colKoszty .sub {
    padding: 15px 15px 0 15px;
    border-radius: 50px;
    padding-top: 20px;
  }

  .borderkoszty {
    display: none;
  }

  .rowKosztyIcon {
    margin-bottom: 0;
  }

  .colKoszty .subOpis {
    padding-bottom: 0;
  }

  .kosztyikonka {
    display: none;
  }

  .colImgCechy .subsub {
    background: transparent;
  }

  .contKoszty {
    margin-bottom: 70px;
  }

  .contCechyHome .row:last-child .colImgCechy .sub {
    padding-bottom: 60px;
    position: relative;
  }

  .contCechyHome .row:last-child .colImgCechy .sub:after {
    width: 2px;
    content: '';
    position: absolute;
    left: calc(50% - 1px);
    bottom: 0;
    height: 60px;
    background: #EFEFEF;
  }

  .abselementkorzysci {
    top: 65px;
  }

  .colKorzysci {
    margin-bottom: 30px;
  }

  .korzysciSection {
    margin-bottom: 20px;
  }

  .colKorzysci .korzysciIcon {
    margin-bottom: 15px;
  }

  .contKompetencja .sub {
    margin: 0;
    padding: 0 10px;
  }

  .contKompetencja .sub .galeriaSlick .prevSlid {
    width: 45px;
    left: -5px;
  }

  .contKompetencja .sub .galeriaSlick .nextSlid {
    width: 45px;
    right: -5px;
  }

  .subwrapClientImg .nazwakomp {
    font-size: 16px;
  }

  .contKompetencja {
    margin-bottom: 30px;
  }

  .contPracaHome {
    background: none;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .opisPracaHome .sub {
    background: #15c1f3;
    padding: 25px;
    border-radius: 50px;
  }

  footer {
    margin-top: 50px;
    padding-bottom: 0px;
  }

  .cF4 {
    order: 4;
  }

  .formNestedRow input.wpcf7-submit {
    float: none;
    margin: 0 auto;
  }

  .colObrazekInner {
    order: 2;
    text-align: center !important;
    margin: 15px 0;
  }

  .colObrazekInner.text-right {
    text-align: center !important;
  }

  .contHeadOferta {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .procesOpis {
    text-align: center;
  }

  .opis.opisReduceH h1,
  .opis.opisReduceH h2,
  .opis.opisReduceH h3,
  .opis.opisReduceH h4,
  .opis.opisReduceH h5,
  .opis.opisReduceH h6 {
    line-height: 35px;
  }

  .contProcesOferta {
    margin-bottom: 30px;
  }

  .colObrLetters img {
    max-height: 250px;
    margin-bottom: 15px;
  }

  .rowSpecjalnosciOferta {
    margin-bottom: 50px;
  }

  .opisSpecjalnosci table ul {
    padding: 0 15px;
  }

  .opisSpecjalnosci {
    text-align: center;
  }

  .trescakk {
    line-height: 2;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 25px;
    background-size: 15px;
    background-position: right 10px center;
    text-align: center;
  }

  .opis.opisKariera td {
    padding-right: 0;
    display: block;
    padding-bottom: 10px;
  }

  .contentCollapseAcc {
    padding: 0 15px;
  }

  .karieraInt {
    font-size: 18px;
    line-height: 1.3;
  }

  .opis.opisKariera table {
    margin-bottom: 10px;
  }

  .opis.opisKariera {
    padding-top: 20px;
  }

  .colObrCeleOnas {
    order: 2;
    margin-bottom: 30px;
  }

  .opisSpecjalnosci {
    text-align: center !important;
    margin-bottom: 15px;
  }

  .opisCeleOnas {
    margin-bottom: 40px;
  }

  .rowCeleOnas {
    margin-bottom: 10px;
  }

  .opis.opisSpecjalnosci h1,
  .opis.opisSpecjalnosci h2,
  .opis.opisSpecjalnosci h3,
  .opis.opisSpecjalnosci h4,
  .opis.opisSpecjalnosci h5,
  .opis.opisSpecjalnosci h6 {
    font-size: 26px;
    line-height: 1.3 !important;
  }

  .contHeadOferta.contonas {
    margin-top: 30px;
  }

  .hasloNaRynku .haslor1 {
    font-size: 80px;
  }

  .hasloNaRynku {
    border-left-width: 10px;
    padding-left: 15px;
    margin-top: 15px;
  }

  .elementonas {
    top: -675px;
    max-width: 1000px;
    right: 0;
    opacity: 0.5;
  }

  .colDaneLeft .sub {
    padding: 15px;
    border-radius: 15px 15px 0 0 !important;
  }

  .colDaneRight .sub {
    border-radius: 0 0 15px 15px !important;
  }

  .colDaneRight {
    order: 2;
  }

  .rowKontaktDane {
    margin-bottom: 60px;
  }

  .titleKontakt {
    margin-right: 20px;
    font-size: 20px;
  }

  .daneFirma {
    margin-bottom: 10px;
  }

  .contKontaktForm .sub {
    border-radius: 50px;
  }

  .titleKontaktForm {
    padding: 20px 15px 0 15px;
    font-size: 30px;
    line-height: 1.3;
  }

  .formNestedRow.rowKontaktForm input,
  .formNestedRow.rowKontaktForm textarea {
    width: calc(100% - 30px);
    margin: 10px 15px;
    padding: 0;
    font-size: 17px;
  }

  footer.kont {
    margin-top: 50px;
  }

  .rowKontaktForm.formNestedRow input.wpcf7-submit {
    width: auto;
    background-position: right 14px center;
    background-size: 7px;
    padding: 7px 30px 7px 15px;
    border-radius: 34px;
    font-size: 17px;
  }

  .rowKontaktForm span.wpcf7-not-valid-tip {
    padding-left: 15px;
    padding-right: 15px;
  }

  .colObrazekInner .subForImg::after {
    border-color: rgba(21, 193, 243, 0.2) !important;
  }

  .colObrazekInner .subForImg::before {
    border-color: rgba(21, 193, 243, 0.3) !important;
  }
}

@media (max-width: 575.98px) {
  .colImgCechy .subsub {
    max-width: 200px;
    margin: 0 auto;
  }

  .colGal img {
    height: 200px;
  }

  .colGal {
    margin-bottom: 15px;
  }

  .daneTitle {
    margin-bottom: 20px;
  }

  .tableDaneF td {
    padding-bottom: 15px;
  }

  .tableDaneF {
    font-size: 17px;
  }

  .opis h1,
  .opis h2,
  .opis h3,
  .opis h4,
  .opis h5,
  .opis h6,
  .opisHead h1,
  .opisHead h2,
  .opisHead h3,
  .opisHead h4,
  .opisHead h5,
  .opisHead h6 {
    font-size: 27px;
  }

  .colKoszty .sub h1,
  .colKoszty .sub h2,
  .colKoszty .sub h3,
  .colKoszty .sub h4,
  .colKoszty .sub h5,
  .colKoszty .sub h6 {
    font-size: 27px;
  }

  .logoFooter img {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .subForImg {
    width: 270px;
  }
}

@media (max-width: 350px) {
  .hasloSlid1 {
    font-size: 37px;
  }

  .hasloSlid2 {
    font-size: 25px;
  }
}

@media (max-width: 330px) {
  .formNestedRow.rowKontaktForm input,
  .formNestedRow.rowKontaktForm textarea {
    width: calc(100% - 40px);
  }
}